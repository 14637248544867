<template>
  <el-dialog
    title="Thông tin học sinh"
    :visible.sync="dialogVisible"
    width="90%"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    top="3vh"
  >
    <el-form
      :model="formAllTabStudent"
      :rules="rules"
      label-width="130px"
      label-position="left"
      ref="formAllTabStudent"
    >
      <el-row :gutter="40">
        <el-col :span="4">
          <div class="grid-content">
            <div>
              <el-radio-group
                style="margin-top: 15px"
                v-model="formAllTabStudent.kidMainInfo.kidStatus"
                class="group-radio-status"
                @change="changeKidsStatus()"
              >
                <el-radio label="STUDYING" class="kids-status"
                  >Đang học</el-radio
                >
                <el-radio label="STUDY_WAIT" class="kids-status"
                  >Chờ học</el-radio
                >
                <el-radio label="RESERVE" class="kids-status">Bảo lưu</el-radio>
                <el-radio label="LEAVE_SCHOOL" class="kids-status"
                  >Nghỉ học</el-radio
                >
              </el-radio-group>
            </div>
            <div>
              <el-form-item
                prop="kidMainInfo.dateRetain"
                style="margin-left: -120px"
                v-if="formAllTabStudent.kidMainInfo.kidStatus == 'RESERVE'"
              >
                <el-date-picker
                  style="width: 160px"
                  type="date"
                  format="dd-MM-yyyy"
                  value-format="yyyy-MM-dd"
                  placeholder="Ngày bảo lưu"
                  v-model="formAllTabStudent.kidMainInfo.dateRetain"
                  :disabled="showDateStatus"
                ></el-date-picker>
              </el-form-item>

              <el-form-item
                prop="kidMainInfo.dateLeave"
                style="margin-left: -120px"
                v-if="formAllTabStudent.kidMainInfo.kidStatus == 'LEAVE_SCHOOL'"
              >
                <el-date-picker
                  style="width: 160px"
                  v-model="formAllTabStudent.kidMainInfo.dateLeave"
                  type="date"
                  format="dd-MM-yyyy"
                  value-format="yyyy-MM-dd"
                  placeholder="Ngày nghỉ học"
                  :disabled="showDateStatus"
                ></el-date-picker>
              </el-form-item>
            </div>
          </div>
        </el-col>

        <!-- thông tin học sinh -->
        <el-col :span="10">
          <div class="grid-content">
            <el-form-item label="Khối học" prop="kidMainInfo.gradeName">
              <el-input
                disabled
                v-model="formAllTabStudent.kidMainInfo.gradeName"
              ></el-input>
            </el-form-item>
            <el-form-item label="Lớp học" prop="kidMainInfo.className">
              <el-input
                disabled
                v-model="formAllTabStudent.kidMainInfo.className"
              ></el-input>
            </el-form-item>
            <el-form-item label="Họ tên" prop="kidMainInfo.fullName">
              <el-input
                clearable
                v-model="formAllTabStudent.kidMainInfo.fullName"
              ></el-input>
            </el-form-item>
            <el-form-item label="Biệt danh" prop="kidMainInfo.nickName">
              <el-input
                clearable
                v-model="formAllTabStudent.kidMainInfo.nickName"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="Người đại diện"
              prop="kidMainInfo.representation"
            >
              <el-radio-group
                v-model="formAllTabStudent.kidMainInfo.representation"
              >
                <el-tooltip
                  effect="dark"
                  content="Số điện thoại người đại diện là tài khoản ứng dụng OneKids và nhận tin nhắn SMS từ nhà trường"
                  placement="top-start"
                >
                  <el-radio label="Bố">Bố</el-radio>
                </el-tooltip>
                <el-tooltip
                  effect="dark"
                  content="Số điện thoại người đại diện là tài khoản ứng dụng OneKids và nhận tin nhắn SMS từ nhà trường"
                  placement="top-start"
                >
                  <el-radio label="Mẹ">Mẹ</el-radio>
                </el-tooltip>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="Ngày sinh" prop="kidMainInfo.birthDay">
              <el-date-picker
                v-model="formAllTabStudent.kidMainInfo.birthDay"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                type="date"
                 :picker-options="pickerOptions"
                placeholder="Chọn ngày"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
            <el-form-item prop="kidMainInfo.gender" label="Giới tính">
              <el-radio-group v-model="formAllTabStudent.kidMainInfo.gender">
                <el-radio label="Nam">Nam</el-radio>
                <el-radio label="Nữ">Nữ</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="Địa chỉ" prop="kidMainInfo.address">
              <el-input
                clearable
                v-model="formAllTabStudent.kidMainInfo.address"
              ></el-input>
            </el-form-item>
            <el-form-item label="Ngày nhập học" prop="kidMainInfo.dateStart">
              <el-date-picker
                v-model="formAllTabStudent.kidMainInfo.dateStart"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                type="date"
                style="width: 100%"
                placeholder="Chọn ngày"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="Ghi chú" prop="kidMainInfo.note">
              <el-input
                type="textarea"
                :rows="2"
                v-model="formAllTabStudent.kidMainInfo.note"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="grid-content">
            <el-form-item label="Họ tên bố" prop="kidMainInfo.fatherName">
              <el-input
                clearable
                v-model="formAllTabStudent.kidMainInfo.fatherName"
              ></el-input>
            </el-form-item>
            <el-form-item label="Ngày sinh" prop="kidMainInfo.fatherBirthday">
              <el-date-picker
                v-model="formAllTabStudent.kidMainInfo.fatherBirthday"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                type="date"
                 :picker-options="pickerOptions"
                style="width: 100%"
                placeholder="Chọn ngày"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="Số điện thoại" prop="kidMainInfo.fatherPhone">
              <el-input
                type="number"
                min="0"
                max="0"
                v-model="formAllTabStudent.kidMainInfo.fatherPhone"
              ></el-input>
            </el-form-item>
            <el-form-item label="Email" prop="kidMainInfo.fatherEmail">
              <el-input
                clearable
                v-model="formAllTabStudent.kidMainInfo.fatherEmail"
              ></el-input>
            </el-form-item>
            <el-form-item label="Nghề nghiệp" prop="kidMainInfo.fatherJob">
              <el-input
                clearable
                v-model="formAllTabStudent.kidMainInfo.fatherJob"
              ></el-input>
            </el-form-item>
            <el-form-item label="Họ tên mẹ" prop="kidMainInfo.motherName">
              <el-input
                clearable
                v-model="formAllTabStudent.kidMainInfo.motherName"
              ></el-input>
            </el-form-item>
            <el-form-item label="Ngày sinh" prop="kidMainInfo.motherBirthday">
              <el-date-picker
                v-model="formAllTabStudent.kidMainInfo.motherBirthday"
                format="dd-MM-yyyy"
                value-format="yyyy-MM-dd"
                type="date"
                 :picker-options="pickerOptions"
                style="width: 100%"
                placeholder="Chọn ngày"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="Số điện thoại" prop="kidMainInfo.motherPhone">
              <el-input
                type="number"
                min="0"
                max="0"
                v-model="formAllTabStudent.kidMainInfo.motherPhone"
              ></el-input>
            </el-form-item>
            <el-form-item label="Email" prop="kidMainInfo.motherEmail">
              <el-input
                clearable
                v-model="formAllTabStudent.kidMainInfo.motherEmail"
              ></el-input>
            </el-form-item>
            <el-form-item label="Nghề nghiệp" prop="kidMainInfo.motherJob">
              <el-input
                clearable
                v-model="formAllTabStudent.kidMainInfo.motherJob"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>

    <!-- buton lick -->
    <div class="tab-infor-button" v-if="showButtonClick">
      <el-button
        type="danger"
        size="medium"
        @click="closeDialogByButton('formAllTabStudent')"
      >
        <i class="el-icon-circle-close" />
        <span>Đóng</span>
      </el-button>
      <el-button
        type="success"
        size="medium"
        :loading="loadingButton"
        mini
        @click="submitForm('formAllTabStudent')"
      >
        <i class="el-icon-circle-check" />
        <span>Lưu</span>
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import StudentService from "@/services/StudentService";
import SchoolMasterService from "@/services/SchoolService/SchoolMasterService";
export default {
  props: {
    dialogVisible: null,
  },
  data() {
    let checkDate = (rule, value, callback) => {
      if (value > new Date()) {
        callback(new Error("Ngày phải nhỏ hơn ngày hiện tại"));
      } else {
        callback();
      }
    };
    return {
      idKidTranfer: "",
      showDateStatus: true,
      showButtonClick: true,
      formAllTabStudent: {
        kidMainInfo: {
          idGrade: "",
          idClass: "",
          kidStatus: "",
          kidCode: "",
          fullName: "",
          birthDay: "",
          gender: "",
          nickName: "",
          address: "",
          dateStart: "",
          dateRetain: "",
          dateLeave: "",
          note: "",
          representation: "",
          fatherName: "",
          fatherBirthday: "",
          fatherPhone: "",
          fatherEmail: "",
          fatherJob: "",
          motherName: "",
          motherBirthday: "",
          motherPhone: "",
          motherEmail: "",
          motherJob: "",
        },
      },
       pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      value: "",
      newStudentCreated: null,
      loadingButton: false,
      rules: {
        kidMainInfo: {
          fullName: [
            {
              required: true,
              message: "Họ tên không được để trống",
              trigger: "change",
            },
            {
              required: true,
              message: "Họ tên không được để trống",
              trigger: "blur",
            },
          ],
          gender: [
            {
              required: true,
              message: "Giới tính không được để trống",
              trigger: "change",
            },
          ],
          birthDay: [
            {
              required: true,
              message: "Ngày sinh không được để trống",
              trigger: "change",
            },
            { validator: checkDate, trigger: "change" },
          ],
          dateStart: [
            {
              required: true,
              message: "Ngày nhập học không được để trống",
              trigger: "change",
            },
          ],
          dateRetain: [
            {
              required: true,
              message: "Chọn ngày bảo lưu",
              trigger: "change",
            },
          ],
          dateLeave: [
            {
              required: true,
              message: "Chọn ngày nghỉ học",
              trigger: "change",
            },
          ],
          fatherBirthday: [{ validator: checkDate, trigger: "change" }],
          motherBirthday: [{ validator: checkDate, trigger: "change" }],
          representation: [
            {
              required: true,
              message: "Người đại diện không được để trống",
              trigger: "change",
            },
          ],
          fatherPhone: [
            {
              min: 10,
              max: 10,
              message: "Số điện thoại bao gồm 10 số",
              trigger: "change",
            },
          ],
          motherPhone: [
            {
              min: 10,
              max: 10,
              message: "Số điện thoại bao gồm 10 số",
              trigger: "change",
            },
          ],
        },
      },
    };
  },
  methods: {
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;";
    },
    //reset when click x
    closeDialog() {
      this.$refs["formAllTabStudent"].resetFields();
      this.$emit("dialog-close");
      setTimeout(() => {}, 300);
    },
    //click when click button
    closeDialogByButton(fromData) {
      this.$refs[fromData].resetFields();
      this.$emit("dialog-close");
      this.$refs[fromData].resetFields();
      setTimeout(() => {}, 300);
    },
    clickNameInitial(data) {
      if (data == "fullName") {
        this.showButtonClick = false;
      } else if (data == "noFullName") {
        this.showButtonClick = true;
      }
    },
    // changeKidsStatus() {
    //   let status = this.formAllTabStudent.kidMainInfo.kidStatus;
    //   if (status == "RESERVE" || status == "LEAVE_SCHOOL") {
    //     this.showDateStatus = false;
    //   } else {
    //     this.showDateStatus = true;
    //   }
    // },
    // submitForm(fromData) {
    //   this.$refs[fromData].validate((valid) => {
    //     if (valid) {
    //       this.loadingButton = true;
    //       let iconArray1 = this.parentIconAppUpdate.parentIconUpdate1;
    //       let iconArray2 = this.parentIconAppUpdate.parentIconUpdate2;
    //       let iconArray = iconArray1.concat(iconArray2);
    //       this.formAllTabStudent.parentIconApp = iconArray;
    //       this.formAllTabStudent.kidsExtraInfo = this.kidsExtraInfoUpdate;
    //       let formDataInput = this.formAllTabStudent;
    //       this.submitAvatar()
    //         .then(() => {
    //           this.submitData(formDataInput, fromData);
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //     } else {
    //       console.log("error validate data!");
    //       this.loadingButton = false;
    //       return false;
    //     }
    //     // location.reload();
    //   });
    // },

    submitForm(fromData) {
      this.$refs[fromData].validate((valid) => {
        if (valid) {
          SchoolMasterService.updateExtra(this.formAllTabStudent)
            .then((resp) => {
              this.$message({
                message: resp.data.message,
                type: "success",
              });
              setTimeout(() => {
                this.closeDialogByButton(fromData);
              }, 500);
            })
            .catch((err) => {
              this.$message({
                message: err.response.data.message,
                type: "error",
              });
            })
            .finally(() => {
              setTimeout(() => {
                this.loadingButton = false;
              }, 500);
            });
        } else {
          console.log("error validate data!");
          return false;
        }
      });
    },

    async getDataInitial(id) {
      this.idKidTranfer = id;
      await this.getExtraKids();
    },

    /**
     * get kids by id
     */
    async getExtraKids() {
      await StudentService.getIdExtra(this.idKidTranfer)
        .then((resp) => {
          this.formAllTabStudent = resp.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-dialog__title {
  font-size: 25px;
  color: #606266;
}
/deep/.el-dialog__body {
  padding-top: 0;
}

/deep/.el-tabs__nav {
  background: #a0a19ce0;
}
/deep/.el-tabs__item {
  color: white;
}
/deep/.el-tabs__item.is-active {
  color: white;
  background: #67c23a;
}
/deep/.el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}
/deep/.el-button--primary {
  width: 100%;
  background: #409eff;
}
/deep/.upload-demo {
  text-align: center;
  .el-upload {
    width: 100%;
  }
}
/deep/.el-avatar {
  display: inline;
  img {
    border-radius: 5px;
    width: 100%;
  }
}
/deep/.el-input.is-disabled .el-input__inner {
  // text-align: center;
  // color: #d13b93;
  // font-weight: bold;
  color: #606266;
}
/deep/.el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:after {
  content: "*";
  color: #f56c6c;
  margin-left: 3px;
}
/deep/.el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before {
  content: "";
  color: #f56c6c;
  margin-left: -4px;
}
/deep/input::-webkit-outer-spin-button,
/deep/input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.el-select {
  display: block;
}
.table-icon-1 {
  width: 30%;
  margin-left: 15%;
  display: inline-block;
}
.table-icon-2 {
  width: 30%;
  margin-right: 15%;
  float: right;
}

.tab-infor-button {
  text-align: right;
  margin-top: 20px;
}
.bg-purple {
  background: #d3dce6;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.group-radio-status {
  margin: 0 50px;
  .kids-status {
    margin-bottom: 10px;
  }
}
</style>