var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thông tin học sinh",
        visible: _vm.dialogVisible,
        width: "90%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "3vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "formAllTabStudent",
          attrs: {
            model: _vm.formAllTabStudent,
            rules: _vm.rules,
            "label-width": "130px",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 40 } },
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _c("div", { staticClass: "grid-content" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "group-radio-status",
                          staticStyle: { "margin-top": "15px" },
                          on: {
                            change: function ($event) {
                              return _vm.changeKidsStatus()
                            },
                          },
                          model: {
                            value: _vm.formAllTabStudent.kidMainInfo.kidStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabStudent.kidMainInfo,
                                "kidStatus",
                                $$v
                              )
                            },
                            expression:
                              "formAllTabStudent.kidMainInfo.kidStatus",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              staticClass: "kids-status",
                              attrs: { label: "STUDYING" },
                            },
                            [_vm._v("Đang học")]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticClass: "kids-status",
                              attrs: { label: "STUDY_WAIT" },
                            },
                            [_vm._v("Chờ học")]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticClass: "kids-status",
                              attrs: { label: "RESERVE" },
                            },
                            [_vm._v("Bảo lưu")]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticClass: "kids-status",
                              attrs: { label: "LEAVE_SCHOOL" },
                            },
                            [_vm._v("Nghỉ học")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _vm.formAllTabStudent.kidMainInfo.kidStatus == "RESERVE"
                        ? _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-left": "-120px" },
                              attrs: { prop: "kidMainInfo.dateRetain" },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "160px" },
                                attrs: {
                                  type: "date",
                                  format: "dd-MM-yyyy",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "Ngày bảo lưu",
                                  disabled: _vm.showDateStatus,
                                },
                                model: {
                                  value:
                                    _vm.formAllTabStudent.kidMainInfo
                                      .dateRetain,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formAllTabStudent.kidMainInfo,
                                      "dateRetain",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formAllTabStudent.kidMainInfo.dateRetain",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.formAllTabStudent.kidMainInfo.kidStatus ==
                      "LEAVE_SCHOOL"
                        ? _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-left": "-120px" },
                              attrs: { prop: "kidMainInfo.dateLeave" },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "160px" },
                                attrs: {
                                  type: "date",
                                  format: "dd-MM-yyyy",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "Ngày nghỉ học",
                                  disabled: _vm.showDateStatus,
                                },
                                model: {
                                  value:
                                    _vm.formAllTabStudent.kidMainInfo.dateLeave,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formAllTabStudent.kidMainInfo,
                                      "dateLeave",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formAllTabStudent.kidMainInfo.dateLeave",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 10 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Khối học",
                          prop: "kidMainInfo.gradeName",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.formAllTabStudent.kidMainInfo.gradeName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabStudent.kidMainInfo,
                                "gradeName",
                                $$v
                              )
                            },
                            expression:
                              "formAllTabStudent.kidMainInfo.gradeName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Lớp học",
                          prop: "kidMainInfo.className",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.formAllTabStudent.kidMainInfo.className,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabStudent.kidMainInfo,
                                "className",
                                $$v
                              )
                            },
                            expression:
                              "formAllTabStudent.kidMainInfo.className",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Họ tên",
                          prop: "kidMainInfo.fullName",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.formAllTabStudent.kidMainInfo.fullName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabStudent.kidMainInfo,
                                "fullName",
                                $$v
                              )
                            },
                            expression:
                              "formAllTabStudent.kidMainInfo.fullName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Biệt danh",
                          prop: "kidMainInfo.nickName",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.formAllTabStudent.kidMainInfo.nickName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabStudent.kidMainInfo,
                                "nickName",
                                $$v
                              )
                            },
                            expression:
                              "formAllTabStudent.kidMainInfo.nickName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Người đại diện",
                          prop: "kidMainInfo.representation",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value:
                                _vm.formAllTabStudent.kidMainInfo
                                  .representation,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formAllTabStudent.kidMainInfo,
                                  "representation",
                                  $$v
                                )
                              },
                              expression:
                                "formAllTabStudent.kidMainInfo.representation",
                            },
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "Số điện thoại người đại diện là tài khoản ứng dụng OneKids và nhận tin nhắn SMS từ nhà trường",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: "Bố" } }, [
                                  _vm._v("Bố"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  effect: "dark",
                                  content:
                                    "Số điện thoại người đại diện là tài khoản ứng dụng OneKids và nhận tin nhắn SMS từ nhà trường",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: "Mẹ" } }, [
                                  _vm._v("Mẹ"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Ngày sinh",
                          prop: "kidMainInfo.birthDay",
                        },
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            format: "dd-MM-yyyy",
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            "picker-options": _vm.pickerOptions,
                            placeholder: "Chọn ngày",
                          },
                          model: {
                            value: _vm.formAllTabStudent.kidMainInfo.birthDay,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabStudent.kidMainInfo,
                                "birthDay",
                                $$v
                              )
                            },
                            expression:
                              "formAllTabStudent.kidMainInfo.birthDay",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "kidMainInfo.gender",
                          label: "Giới tính",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.formAllTabStudent.kidMainInfo.gender,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formAllTabStudent.kidMainInfo,
                                  "gender",
                                  $$v
                                )
                              },
                              expression:
                                "formAllTabStudent.kidMainInfo.gender",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: "Nam" } }, [
                              _vm._v("Nam"),
                            ]),
                            _c("el-radio", { attrs: { label: "Nữ" } }, [
                              _vm._v("Nữ"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Địa chỉ",
                          prop: "kidMainInfo.address",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.formAllTabStudent.kidMainInfo.address,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabStudent.kidMainInfo,
                                "address",
                                $$v
                              )
                            },
                            expression: "formAllTabStudent.kidMainInfo.address",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Ngày nhập học",
                          prop: "kidMainInfo.dateStart",
                        },
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            format: "dd-MM-yyyy",
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            placeholder: "Chọn ngày",
                          },
                          model: {
                            value: _vm.formAllTabStudent.kidMainInfo.dateStart,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabStudent.kidMainInfo,
                                "dateStart",
                                $$v
                              )
                            },
                            expression:
                              "formAllTabStudent.kidMainInfo.dateStart",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "Ghi chú", prop: "kidMainInfo.note" } },
                      [
                        _c("el-input", {
                          attrs: { type: "textarea", rows: 2 },
                          model: {
                            value: _vm.formAllTabStudent.kidMainInfo.note,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabStudent.kidMainInfo,
                                "note",
                                $$v
                              )
                            },
                            expression: "formAllTabStudent.kidMainInfo.note",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-col", { attrs: { span: 10 } }, [
                _c(
                  "div",
                  { staticClass: "grid-content" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Họ tên bố",
                          prop: "kidMainInfo.fatherName",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.formAllTabStudent.kidMainInfo.fatherName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabStudent.kidMainInfo,
                                "fatherName",
                                $$v
                              )
                            },
                            expression:
                              "formAllTabStudent.kidMainInfo.fatherName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Ngày sinh",
                          prop: "kidMainInfo.fatherBirthday",
                        },
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            format: "dd-MM-yyyy",
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            "picker-options": _vm.pickerOptions,
                            placeholder: "Chọn ngày",
                          },
                          model: {
                            value:
                              _vm.formAllTabStudent.kidMainInfo.fatherBirthday,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabStudent.kidMainInfo,
                                "fatherBirthday",
                                $$v
                              )
                            },
                            expression:
                              "formAllTabStudent.kidMainInfo.fatherBirthday",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Số điện thoại",
                          prop: "kidMainInfo.fatherPhone",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { type: "number", min: "0", max: "0" },
                          model: {
                            value:
                              _vm.formAllTabStudent.kidMainInfo.fatherPhone,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabStudent.kidMainInfo,
                                "fatherPhone",
                                $$v
                              )
                            },
                            expression:
                              "formAllTabStudent.kidMainInfo.fatherPhone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Email",
                          prop: "kidMainInfo.fatherEmail",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { clearable: "" },
                          model: {
                            value:
                              _vm.formAllTabStudent.kidMainInfo.fatherEmail,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabStudent.kidMainInfo,
                                "fatherEmail",
                                $$v
                              )
                            },
                            expression:
                              "formAllTabStudent.kidMainInfo.fatherEmail",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Nghề nghiệp",
                          prop: "kidMainInfo.fatherJob",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.formAllTabStudent.kidMainInfo.fatherJob,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabStudent.kidMainInfo,
                                "fatherJob",
                                $$v
                              )
                            },
                            expression:
                              "formAllTabStudent.kidMainInfo.fatherJob",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Họ tên mẹ",
                          prop: "kidMainInfo.motherName",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.formAllTabStudent.kidMainInfo.motherName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabStudent.kidMainInfo,
                                "motherName",
                                $$v
                              )
                            },
                            expression:
                              "formAllTabStudent.kidMainInfo.motherName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Ngày sinh",
                          prop: "kidMainInfo.motherBirthday",
                        },
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            format: "dd-MM-yyyy",
                            "value-format": "yyyy-MM-dd",
                            type: "date",
                            "picker-options": _vm.pickerOptions,
                            placeholder: "Chọn ngày",
                          },
                          model: {
                            value:
                              _vm.formAllTabStudent.kidMainInfo.motherBirthday,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabStudent.kidMainInfo,
                                "motherBirthday",
                                $$v
                              )
                            },
                            expression:
                              "formAllTabStudent.kidMainInfo.motherBirthday",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Số điện thoại",
                          prop: "kidMainInfo.motherPhone",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { type: "number", min: "0", max: "0" },
                          model: {
                            value:
                              _vm.formAllTabStudent.kidMainInfo.motherPhone,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabStudent.kidMainInfo,
                                "motherPhone",
                                $$v
                              )
                            },
                            expression:
                              "formAllTabStudent.kidMainInfo.motherPhone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Email",
                          prop: "kidMainInfo.motherEmail",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { clearable: "" },
                          model: {
                            value:
                              _vm.formAllTabStudent.kidMainInfo.motherEmail,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabStudent.kidMainInfo,
                                "motherEmail",
                                $$v
                              )
                            },
                            expression:
                              "formAllTabStudent.kidMainInfo.motherEmail",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "Nghề nghiệp",
                          prop: "kidMainInfo.motherJob",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.formAllTabStudent.kidMainInfo.motherJob,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAllTabStudent.kidMainInfo,
                                "motherJob",
                                $$v
                              )
                            },
                            expression:
                              "formAllTabStudent.kidMainInfo.motherJob",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showButtonClick
        ? _c(
            "div",
            { staticClass: "tab-infor-button" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialogByButton("formAllTabStudent")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v("Đóng")]),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingButton,
                    mini: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("formAllTabStudent")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v("Lưu")]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }